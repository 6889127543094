import { Controller } from "stimulus"
import * as $ from 'jquery'
window.$ = window.jQuery = $

export default class extends Controller {
  sendData(_event){
    var input = $('.sign-up-component .input-block input')[0]
    if (!input.checkValidity()){ return input.reportValidity() }    
    $.ajax
    ({
      url:      this.endpoint(),
      type:     'post',
      dataType: 'json',
      data:    this.bodyData(),
      success: () => {
        this.handleSuccess()
      },
      error: response => {
        console.log('error', response)
      }
    })
  }

  email(){
    return $('.sign-up-component .input-block input').val()
  }

  handleSuccess(){
    $('.sign-up-component .input-block').empty()
    $('.sign-up-component .suffix-content').empty()
    $('.sign-up-component .suffix').prepend(`<p class="m-0 text-grey-500">${this.successMessage()}</p>`)
  }

  successMessage(){
    return 'Thanks for signing up!'
  }

  bodyData(){
    return { registration: 
      {
        email: this.email, 
        request_location: window.location.href,
        content_id: this.contentId(),
        language_code: this.languageCode(),
        category_slug: this.primaryCategorySlug()
      }
    }
  }

  endpoint(){
    return '/api/registrations'
  }

  // For content only
  // --------------------------------------------------------
  contentId(){
    var contentId =  $(".page-content .content input[type='hidden']#registrations_post_id").val() 
    if (contentId) { return contentId }
  }

  languageCode(){
    const languageCode = $(".page-content .content input[type='hidden']#registrations_post_languages").val();
    return languageCode && languageCode !== 'en' ? languageCode : undefined;
  }

  primaryCategorySlug(){
    const primaryCategorySlug = $(".page-content .content input[type='hidden']#registrations_primary_category_slug").val();
    if (primaryCategorySlug) { return primaryCategorySlug}
  }
  // --------------------------------------------------------
}
