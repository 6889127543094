import { Controller } from "stimulus"

const SUB_CATEGORIES_MENU_SIDE_PADDING = 40;   // px
const SUB_CATEGORIES_MENU_SIDE_PADDING_EXTRA_SPACE = 4;   // px

const BREAKPOINT_WIDE = 1340;
const BREAKPOINT_MINI_WIDE = 1290;
const NINE_COLUMNS_WIDE = 960;

const MENU_POSITION_RIGHT = 'right';
const MENU_POSITION_LEFT = 'left';
const MENU_POSITION_MIDDLE = 'middle';

export default class extends Controller {
  connect() {
    document.querySelectorAll('.global-nav__header-second-level-list > .global-nav__header-item > a').forEach(categoryLink => {
      categoryLink.addEventListener('focus', (e) => {
        e.preventDefault();
        this.hideAllSubCategoriesMenuWrappers();
      });
    });
  }

  show(event) {
    if(event.detail === 0 && !this._isASubCategoryLink(event)) {
      event.preventDefault()
    }

    this.hideAllSubCategoriesMenuWrappers();
    
    const categoryListItem = event.currentTarget;
    const subCategoriesNavMenu = categoryListItem.children['1'];

    const clientWidth = document.body.getBoundingClientRect().width;
    const categoryListItemPosition = categoryListItem.getBoundingClientRect();

    const subCategoriesNavMenuPosition = subCategoriesNavMenu.getBoundingClientRect();

    let marginLeftValue = -8;

    switch(this._getMenuPosition(categoryListItemPosition, subCategoriesNavMenuPosition, clientWidth)) {
      case MENU_POSITION_RIGHT:
        if (this._canAlignSubCategoryText(clientWidth)) {
          marginLeftValue = -SUB_CATEGORIES_MENU_SIDE_PADDING;
        }
      break;
      case MENU_POSITION_LEFT:
        if (!this._canAlignSubCategoryText(clientWidth)) {
          marginLeftValue -= SUB_CATEGORIES_MENU_SIDE_PADDING;
        }
        marginLeftValue = categoryListItemPosition.width + SUB_CATEGORIES_MENU_SIDE_PADDING + marginLeftValue - subCategoriesNavMenuPosition.width;
      break;
      default:
        // MENU_POSITION_MIDDLE
        marginLeftValue = (categoryListItemPosition.width / 2) - (subCategoriesNavMenuPosition.width / 2) - 8;
    }

    subCategoriesNavMenu.style = `transition: visibility 0s linear .2s, max-height .2s ease-in-out .2s; margin-left: ${marginLeftValue}px; visibility: visible; max-height: none;`;
  }

  hide(event) {
    const categoryListItem = event.currentTarget;
    const subCategoriesNavMenu = categoryListItem.children['1'];
    subCategoriesNavMenu.style = null;
  }

  hideAllSubCategoriesMenuWrappers() {
    document.querySelectorAll('.global-new-nav__header-third-level').forEach(subCategoriesMenuWrapper => subCategoriesMenuWrapper.style = null);
  }

  _canAlignSubCategoryText(clientWidth) {
    if (clientWidth >= (BREAKPOINT_MINI_WIDE + 2 * (SUB_CATEGORIES_MENU_SIDE_PADDING + SUB_CATEGORIES_MENU_SIDE_PADDING_EXTRA_SPACE))
        || (NINE_COLUMNS_WIDE + 2 * (SUB_CATEGORIES_MENU_SIDE_PADDING + SUB_CATEGORIES_MENU_SIDE_PADDING_EXTRA_SPACE)) <= clientWidth && clientWidth <= (BREAKPOINT_WIDE - 1)) {
      return true;
    }

    return false;
  }

  _getMenuPosition(itemPosition, menuPosition, clientWidth) {
    if (itemPosition.x + menuPosition.width > clientWidth) {
      if (itemPosition.x + itemPosition.width - menuPosition.width < 1) {
        return MENU_POSITION_MIDDLE;
      }
      
      return MENU_POSITION_LEFT;
    }

    return MENU_POSITION_RIGHT;
  }

  _isASubCategoryLink(event) {
    return ['global-new-nav__header-third-level-link', 'global-new-nav__header-third-level-heading-link'].some(klass => event.target.classList.contains(klass));
  }
}