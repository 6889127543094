import { Controller } from "stimulus"

export default class extends Controller {
  copyDomain(){
    const currentDomain = window.location.href;
    navigator.clipboard.writeText(currentDomain).then(function() {
      const toast = document.getElementById('toast');
      if (toast) {
        toast.className = 'show';
        setTimeout(function() {
            toast.className = toast.className.replace('show', '');
        }, 1000);
      }
    }, function(err) {
        console.error('Could not copy text: ', err);
    });
  }
}
