import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    positionId: String,
  }

  connect() {
    window.fusetag = window.fusetag || { que: [] }
    window.fusetag.que.push(() => {
      window.fusetag.loadSlotById(this.positionIdValue)
    })
  }
}
