import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content', 'divider', 'read_more', 'read_less']

  connect() {
    this._maxHeightInReadLessMode = Number(this.element.dataset.maxHeight) || 100
    this._introductoryTextActualHeight = this.contentTarget.scrollHeight
    if(this._introductoryTextActualHeight > this._maxHeightInReadLessMode) {
        this.read_moreTarget.classList.toggle('show')
    } else {
      this._targets().forEach(el => el.remove())
      this.contentTarget.classList.remove('show-less')
    }
  }

  _getIntroductoryTextHeight() {
    if(this.contentTarget.clientHeight <= this._maxHeightInReadLessMode) {
        return this._introductoryTextActualHeight
    }
    return this._maxHeightInReadLessMode
  }

  _targets() {
    return [this.dividerTarget, this.read_moreTarget, this.read_lessTarget]
  }

  toggleReadMode() {
    this._targets().forEach(el => el.classList.toggle('show'))

    if(this.contentTarget.classList.contains('show-full')) {
      this.contentTarget.classList.remove('show-full')
      this.contentTarget.style = ''
    } else {
      this.contentTarget.style.maxHeight=this._getIntroductoryTextHeight() + 'px'
      this.contentTarget.classList.add('show-full')
    }
  }
}
