import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
  }

  linkClicked(event) {
    const ga3 = {
      "event": "click",
      "eventCategory": "breadcrumbs",
    };
    dataLayer.push(ga3);

    const ga4 = {
      "event": "user_event",
      "event_name": "click",
      "context": "breadcrumbs",
    };
    dataLayer.push(ga4);
  }
}
