import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.menuClass = '.' + (this.element.dataset.menuClass || 'section-header__menu')
    this.hideAll()
    document.body.addEventListener('click', this.hideAll.bind(this))
  }

  displayMenu (e) {
    e?.preventDefault()
    e?.stopPropagation()
    let menu = e.currentTarget.closest(this.menuClass)
    const alreadyOpen = menu.classList.contains('open-popup')
    this.hideAll()
    if(!alreadyOpen) {
      menu.classList.add('open-popup') 
    }
  }

  hideAll() {
    const menus = this.element.querySelectorAll(this.menuClass)
    for (const menu of menus) {
      menu.classList.remove('open-popup')
    }
  }
}
