import {Controller} from "stimulus"

export default class SyncSignupInPostController extends Controller {
  static targets = ["title", "tease", "policy", "image"]
  static values = {
    title: String,
    tease: String,
    policyUrl: String,
    imageUrl: String
  }

  initialize() {
    this.initValue()
  }

  initValue() {
    fetch(this.signupApiUrl()) 
    .then(resp => resp.json()) 
    .then(data => {
      const { title, tease, background_url, privacy_policy_url } = data
      this.titleValue = title
      this.teaseValue = tease
      this.policyUrlValue = privacy_policy_url
      this.imageUrlValue = background_url
    })
    .catch(error => {
      console.log(error)
    })
  }
  
  signupApiUrl() {
    const pageType = "in_content"
    return `/api/email_settings/get_by_page_type?page_type=${pageType}`
  }

  titleValueChanged(value, previousValue) {
    if (value === "") { return }

    this.titleTarget.innerText = value
  }

  teaseValueChanged(value, previousValue) {
    if (value === "") { return }

    this.teaseTarget.innerText = value
  }

  policyUrlValueChanged(value, previousValue) {
    if (value === "") { return }

    this.policyTarget.href = value
  }

  imageUrlValueChanged(value, previousValue) {
    if (value === "") { return }

    this.imageTarget.src = value
  }
}
