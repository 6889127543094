import { Controller } from "stimulus"
import { ajax } from 'jquery'

export default class extends Controller {
  static targets = [
    'suggestionContainer',
    'suggestionList'
  ]

  connect() {
    this.searchInputEl = this.element.querySelector('#search_terms')
    document.addEventListener('click', (e) => {
      const isHidden = (el) => {
        let style = window.getComputedStyle(el);
        return (style.display === 'none')
      }

      if (document.activeElement != this.searchInputEl && !isHidden(this.suggestionContainerTarget) ) {
        this.hideAutoSuggestions = true
        this.focus_out()
      }
    })
  }

  perform_search(event) {
    event?.preventDefault()
    const search_terms = event.target.querySelector('#search_terms').value.trim()
    if(search_terms.length === 0) {
      return
    }

    const form_url = event.target.action
    const url = form_url + '/' + search_terms.replace(/\.+/g, ' ').replace(/\s+/g, '+')
    document.location.href = url
  }

  auto_suggestions(event) {
    const value = event.target.value.trim()
    if (value.length > 3) {
      const api = '/api/search_suggestion'
      ajax({
        url: api,
        type: 'get',
        data: {query: value},
        dataType: 'json',
        success: (data) => {
          this.focus_in(event, true)
          this.createListItems(data)
        }
      })
    } else {
      this.focus_out()
    }
  }

  focus_in(event, forceVisible = false) {
    const value = event.target.value.trim()
    if ((value.length > 3 && this.suggestionListTarget.childElementCount > 0) || forceVisible) {
      this.hideAutoSuggestions = false
      this.suggestionContainerTarget.style.display = 'block'
      this.suggestionContainerTarget.style.width = this.searchInputEl.offsetWidth + 'px'
      this.suggestionContainerTarget.style.top = this.searchInputEl.offsetTop + this.searchInputEl.offsetHeight + 5 + 'px'
    }
  }

  focus_out() {
    if (this.hideAutoSuggestions) {
      this.suggestionContainerTarget.style.display = 'none'
    }
  }

  keydown(e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code == 9 && document.activeElement == this.searchInputEl) {
      this.hideAutoSuggestions = false
    } else {
      this.hideAutoSuggestions = true
    }
  }

  onSuggestionContainerMousedown(event) {
    event.preventDefault()
  }

  createListItems(data) {
    if (data.length == 0) {
      this.focus_out()
      return
    }
    
    this.suggestionListTarget.innerHTML = ''
    const div = document.createElement('div')
    data.forEach((item) => {
      div.innerHTML = this.template(item.name, item.url, item.topic)
      this.suggestionListTarget.appendChild(div.firstChild)
    })
  }

  template(name, url, topic) {
    return (`<li role="presentation" class="search__auto_suggesstion_list_item" dir="ltr">
      <div class="search__auto_suggesstion_item_content" id="" role="option">
        <a href="${url}" class="search__auto_suggesstion_link">
          <span class="search__auto_suggesstion_link_name">${name}</span>
          <span class="search__auto_suggesstion_link_label" id="" role="option">In ${topic}</span>
        </a>
      </div>
      
    </li>`)
  }
}
