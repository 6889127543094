import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.showMoreOrLessLink()
  }

  toggleExapandCollapse() {
    this.element.querySelector('.arrow-down').classList.toggle('hide')
    this.element.querySelector('.arrow-up').classList.toggle('hide')
    this.element.querySelector('.collapsible-section-content-wrapper').classList.toggle('hide')
    this.showMoreOrLessLink()
  }

  showMoreOrLessLink() {
    const sectionContent = this.element.querySelector('.collapsible-section-content')
    sectionContent.classList.remove('collapsible-section-content__max-height')

    if (JSON.parse(this.element.dataset['showAllOptions']) === true) {
      return
    }

    // show 4 list items with show more link
    if(sectionContent.querySelectorAll('li').length <= 4) {
      this.element.querySelector('.show-more-link').classList.add('hide')
      this.element.querySelector('.show-less-link').classList.add('hide')
    } else {
      this.element.querySelector('.show-more-link').classList.remove('hide')
      this.element.querySelector('.show-less-link').classList.add('hide')
    }
    sectionContent.classList.add('collapsible-section-content__max-height')
  }

  toggleMoreOrLess() {
    this.element.querySelector('.collapsible-section-content').classList.toggle('collapsible-section-content__max-height')
    
    this.element.querySelector('.show-more-link').classList.toggle('hide')
    this.element.querySelector('.show-less-link').classList.toggle('hide')
  }
}
