import { Controller } from "stimulus"

const ANNOUCEMENT_BAR_HEIGHT = 52
const NEW_NAV_CATEGORIES_WRAPPER_MIN_HEIGHT = 52

export default class extends Controller {
  connect() {
    window.addEventListener('resize', () => this.onResize())

    this._adjustEmptyBackgroundDivHeight()
  }

  pageContentEl() {
    if (this.pageContent) {
      return this.pageContent
    }
    this.pageContent = document.querySelector('.page-content')
    return this.pageContent
  }

  bodyEl() {
    return document.body
  }

  toggle(event) {
    event.preventDefault()
    this.bodyEl().classList.toggle('body--mobile-nav-open')
    this.adjustContentHeight()
  }

  toggleSecondLevelSection(event) {
    event.preventDefault()
    var link = event.target
    var item = link.closest('.global-nav__mobile-top-level-item')
    const mobile = this.element.querySelector('.global-nav__mobile-top-level-item')
    if (mobile.nodeName != item.nodeName) {
      mobile.classList.remove('global-nav__mobile-top-level-item--expanded')
    }

    this._adjustNewNavHeight(item.classList.toggle('global-nav__mobile-top-level-item--expanded'), '.global-nav__mobile-top-level-item--expanded .global-nav__mobile-second-level-new .global-nav__mobile-new')
  }

  toggleThirdLevelSection(event) {
    event.preventDefault()
    var link = event.target
    var item = link.closest('.global-nav__mobile-second-level-link-item')
    const mobile = this.element.querySelector('.global-nav__mobile-second-level-link-item')
    if (mobile.nodeName != item.nodeName) {
      mobile.classList.remove('global-nav__mobile-second-level-link-item--expanded')
    }

    const isThirdLevelNavExpanded = item.classList.toggle('global-nav__mobile-second-level-link-item--expanded')
    this._adjustNewNavHeight(isThirdLevelNavExpanded, '.global-nav__mobile-second-level-link-item--expanded .global-nav__mobile-third-level-new .global-nav__mobile-new')
    if (!isThirdLevelNavExpanded) {
      this._adjustNewNavHeight(true, '.global-nav__mobile-top-level-item--expanded .global-nav__mobile-second-level-new .global-nav__mobile-new')
    }
  }

  _adjustNewNavHeight(isExpanded, expandedMenuSelector) {
    const newNavWrapper = this.element.querySelector('.new-nav .global-nav__mobile-new')
    const expandedMenuWrapper = this.element.querySelector(expandedMenuSelector)

    if (!newNavWrapper || !expandedMenuWrapper) {
      return;
    }

    if (isExpanded) {
      newNavWrapper.style['min-height'] = `${expandedMenuWrapper.getBoundingClientRect().height}px`
    } else {
      newNavWrapper.style = null
    }
  }

  onResize() {
    this.adjustContentHeight()
    if (document.body.clientWidth > 1023) {
      this.close()
    }
  }

  close() {
    this.bodyEl().classList.remove('body--mobile-nav-open')
  }

  isOpen() {
    this.bodyEl().classList.contains('body--mobile-nav-open')
  }

  adjustContentHeight() {
    if (this.isOpen()) {
      const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      this.pageContentEl().clientHeight = height - 75
    } else {
      this.pageContentEl().style.height = 'auto'
    }
  }

  sendDataLayerEvent(event) {
    postGADataLayerEvents(event, 'navigation')
  }

  sendNewNavDataLayerEvent(event) {
    postGADataLayerEvents(event, 'new_navigation')
  }

  sendSearchDataLayerEvent(event) {
    postGADataLayerEvents(event, 'new_navigation', event.target.querySelector('#search_terms').value)
  }

  onAnnouncementBarClose() {
    this._updateEmptyBackgroundDivHeight(document.querySelector('.empty-sticky-nav-background-div'), -ANNOUCEMENT_BAR_HEIGHT)
  }

  _adjustEmptyBackgroundDivHeight() {
    if (this.element.classList.contains('new-nav')) {
      const emptyBackgroundDiv = document.querySelector('.empty-sticky-nav-background-div')
      const categoriesNavWrapper = document.querySelector('.global-new-nav__header-second-level')

      if (categoriesNavWrapper && categoriesNavWrapper.clientHeight > NEW_NAV_CATEGORIES_WRAPPER_MIN_HEIGHT) {
        this._updateEmptyBackgroundDivHeight(emptyBackgroundDiv, categoriesNavWrapper.clientHeight - NEW_NAV_CATEGORIES_WRAPPER_MIN_HEIGHT)
      }
    }
  }

  _updateEmptyBackgroundDivHeight(emptyBackgroundDiv, offsetHeight) {
    const emptyBackgroundHeight = parseInt(emptyBackgroundDiv.style.height.replace('px', ''))

    emptyBackgroundDiv.style.height = `${emptyBackgroundHeight + offsetHeight}px`
  }
}
