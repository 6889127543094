import application from './stimulus_app'
import { definitionsFromContext } from "stimulus/webpack-helpers"
import '../stylesheets/base.sass'
import { standardisedClickTracking } from '@envato/gtm-analytics'

require.context('images/', true)
require.context('../fonts/', true, /\.(eot|ttf|woff|woff2|otf)$/i)

const context = require.context("../controllers/base", true, /\.js$/)
const contextComponents = require.context("../../components", true, /_controller\.js$/)
// normally foo_component/foo_component_controller.js will have an identifier of foo-component--foo-component
// this strips off any leading dirs and leaves the identifier as just foo-component
const componentDefinitions = definitionsFromContext(contextComponents).map(({identifier, controllerConstructor}) => {
  const pieces = identifier.split("--")
  identifier = pieces[pieces.length - 1]
  return {identifier, controllerConstructor}
});
application.load(
  definitionsFromContext(context).concat(componentDefinitions)
)

if (!window.__appClientLoaded) {
  window.__appClientLoaded = true
  require('@hotwired/turbo')
}

const gtmConfig = {
  tutsplusDomain: window.Tutsplus.apexDomain
};

const gtmClickTracker = event => {
  standardisedClickTracking(event, gtmConfig);
}

window.addEventListener('click', gtmClickTracker);
