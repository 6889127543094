import { Controller } from "stimulus";

export default class extends Controller {
  toggleContentFiltersDialog() {
    this.element.querySelector('[role="dialog"]').classList.toggle('hide')
    
    if(document.body.style.overflow === '') {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }

  clearFilters() {
    location.href = this._currentBaseUrl()
  }

  showResults() {
    location.href = this._getTargetContentFiltersUrl()
  }

  _getTargetContentFiltersUrl() {
    const all_selected_options = {}
    
    document.querySelectorAll('.content-filters__small-screen .content-filters-option-checked:not(.hide)').forEach(e => {
      const selectedOption = e.parentElement
      const optionName = selectedOption.dataset['name']
      const optionValue = selectedOption.dataset['value']

      if(!all_selected_options[optionName]) {
        all_selected_options[optionName] = []
      }

      // avoid duplicate entries
      if(!all_selected_options[optionName].includes(optionValue)) {
        all_selected_options[optionName].push(optionValue)
      }
    })

    let filterUrl = this._locationOrigin()

    // language
    const selectedLanguage = document.querySelector('[data-name="language"] .content-filters-option-checked:not(.hide)').parentElement.dataset["value"]
    if(selectedLanguage !== 'en') {
      filterUrl += ('/' + selectedLanguage)
    }

    // handle split topic
    const isSplitTopic = this._isSplitTopic()
    if(isSplitTopic) {
      filterUrl += ('/' + this._getSplitTopicName())
    }

    // category
    const category_match = this._locationPathname().match(/\/(c\/[a-zA-Z\-]*)(\/|$)/)
    const sub_category_match = this._locationPathname().match(/\/(s\/[a-zA-Z\-]*)(\/|$)/)
    if(category_match) {
      filterUrl += ('/' + category_match[1])
    }
    
    // software
    if(all_selected_options['software_tag']) {
      filterUrl += ('/compatible-with/' + all_selected_options['software_tag'][0])
    } else if(sub_category_match) {
      filterUrl += ('/' + sub_category_match[1])
    }

    // content type
    if((!category_match || !all_selected_options['software_tag']) && all_selected_options['content_type']) {
      filterUrl += ('/t/' + all_selected_options['content_type'][0])
    }

    const url = new URL(filterUrl)

    for(let optionName in all_selected_options) {
      if(!['software_tag', 'content_type', 'language'].includes(optionName)) {
        const optionValues = all_selected_options[optionName]
        optionValues.forEach(optionValue => url.searchParams.append(optionName, optionValue))
      }
    }

    return url.href    
  }

  _isSplitTopic() {
    return this._locationOrigin().includes('photography')
  }

  _getSplitTopicName() {
    if (location.href.includes(location.origin + '/video')) {
      return 'video'
    } else if (location.href.includes(location.origin + '/photo')) {
      return 'photo'
    } else {
      return ""
    }
  }

  _currentBaseUrl() {
    return this._locationOrigin() + this._locationPathname()
  }

  _locationOrigin() {
    return location.origin
  }

  _locationPathname() {
    return location.pathname
  }
}