import {Controller} from "stimulus"

export default class UtmController extends Controller {
  static values = {
    utmSource: { type: String, default: "tutsplus" },
    utmMedium: { type: String, default: "" },
    utmCampaign: { type: String, default: "" },
    languageCode: { type: String, default: 'en' }
  }

  initialize() {
    this.initBinding()
    // Comment the line below to enable this feature for all subdomains
    if (!this.isDesignSubdomain()) { return }

    this.initUtmValue()
    this.addUtmParamsToEnvatoLinks()
  }

  initBinding() {
    this.urlObject = new URL(window.location.href)
    this.searchParams = this.urlObject.searchParams
    this.pathname = this.urlObject.pathname
    this.hostname = this.urlObject.hostname
    this.host = this.urlObject.host
  }

  initUtmValue() {
    if (this.utmMediumValue.length === 0) { this.utmMediumValue = this.getUtmMediumFromUrl() }
    if (this.utmCampaignValue.length === 0) {
      // if (this.getLanguageCode()) {
      //   this.utmCampaignValue = `${this.getLanguageCode()}/${this.getUtmCampaignFromUrl()}`
      if (this.isTranslation()) {
        this.utmCampaignValue = `${this.languageCodeValue}/${this.getUtmCampaignFromUrl()}`
      } else {
        this.utmCampaignValue = this.getUtmCampaignFromUrl()
      }
    }
  }

  addUtmParamsToEnvatoLinks() {
    let linkElements = this.element.querySelectorAll('a')
    linkElements.forEach((linkElement) => {
      if (linkElement.href.length > 0) {
        let linkUrlObject = new URL(linkElement.href)
        if (this.isExtenalEnvatoHost(linkUrlObject) && !this.isIncludeUtmParams(linkUrlObject)) {
          this.addUtmParamsToLink(linkElement)
        }
      }
    })
  }

  isDesignSubdomain() {
    return this.host.startsWith('design')
  }

  isExtenalEnvatoHost(linkUrlObject) {
    let urlHost = linkUrlObject.host
    if (urlHost.length < 1) { return false }

    let result = false
    for (let envatoHost of this.envatoHosts()) {
      if (urlHost.includes(envatoHost)) {
        result = true
        break
      }
    }
    return result
  }

  isIncludeUtmParams(linkUrlObject) {
    if (linkUrlObject.search.length < 1) { return false }

    return linkUrlObject.search.includes('utm_')
  }

  isTranslation() {
    return this.languageCodeValue !== 'en'
  }

  addUtmParamsToLink(linkElement) {
    let linkUrlObject = new URL(linkElement.href)
    linkUrlObject.searchParams.append('utm_campaign', this.utmCampaignValue)
    linkUrlObject.searchParams.append('utm_medium', this.utmMediumValue)
    linkUrlObject.searchParams.append('utm_source', this.utmSourceValue)
    if (this.utmMediumValue.length > 20) {
      linkUrlObject.searchParams.append('debug', this.debugUtmParams(linkElement))
    }
    linkElement.href = linkUrlObject.href
  }

  // use debugUtmParams to debug the utm params errors on GA4. If there are no errors, we can remove this method
  debugUtmParams(linkElement) {
    return `${window.location.href}---${JSON.stringify(this.searchParams)}---${this.pathname}---${this.hostname}---${this.host}---${this.utmMediumValue}---${this.utmCampaignValue}---${linkElement.className}---${linkElement.innerText}`
  }

  getUtmMediumFromUrl() {
    if (this.searchParams.size === 0 && this.pathname.split('/').includes('search')) { return 'search' }
    if (this.pathname.split('/').includes('c') || this.pathname.split('/').includes('s')) { return 'listing' }
    if (this.searchParams.size === 0 && this.pathname.split('/').includes('compatible-with')) { return 'listing' }
    if (this.searchParams.size > 0 && !this.pathname.split('/').includes('search')) { return 'listing' }
    if (this.pathname === '/') { return "homepage" }
    let lastPathname = this.pathname.split('/').slice(-1)[0]
    if (lastPathname.includes('--')) {
      if (this.pathname.includes('ytc')) { return "ytc" }
      if (this.pathname.endsWith('c')) { return "courses" }
      if (this.pathname.endsWith('a')) { return "articles" }
      if (this.pathname.endsWith('t')) { return "tutorials" }
    }
    if (this.pathname.split('/').length > 2) {
      // if (this.getLanguageCode()) {
      if (this.isTranslation()) {
        return this.pathname.split('/')[2]
      }
      return this.pathname.split('/')[1]
    }
    return 'misc'
  }

  getUtmCampaignFromUrl() {
    if (this.pathname === '/') { return this.hostname }
    if (this.utmMediumValue === 'listing') {
      if (this.pathname.split('/').includes('compatible-with') || this.searchParams.size > 0 || this.pathname.split('/').includes('c') || this.pathname.split('/').includes('s')) {
        return `${this.hostname}${this.pathname}`
      }
    }
    let lastPathname = this.pathname.split('/').slice(-1)[0]
    if (['search', 'listing'].includes(this.utmMediumValue)) { return lastPathname }
    if (lastPathname.includes('--')) {
      if (lastPathname.endsWith('c') || lastPathname.endsWith('t') || lastPathname.endsWith('a')) {
        lastPathname = lastPathname.slice(0, -1)
      }
      return lastPathname.split('--').slice(-1)[0]
    }
    return this.pathname.substring(1)
  }

  getLanguageCode() {
    let languageElement = document.querySelector('.language-selector-wrapper')
    if (languageElement && languageElement.innerText !== "English") {
      let languageCode = this.pathname.split('/')[1]
      return languageCode
    }
  }

  envatoHosts() {
    return ['envato', 'market', 'elements', 'themeforest', 'placeit', 'mixkit', 'codecanyon', 'audiojungle', 'videohive', 'graphicriver', 'photodune', '3docean', 'reshot']
  }

  utmParamsNames() {
    return ['utm_campaign', 'utm_medium', 'utm_source']
  }
}
