import { Controller } from "stimulus"

export default class extends Controller {
  toggleFooterSection(event) {
    event?.preventDefault()
    const item = event.target.closest('.page-footer__section')
    item.classList.toggle('page-footer__section--expanded')
    return this
  }

  openCookieSettings(event) {
    event.preventDefault()
    if (window.Cookiebot) {
      window.Cookiebot.renew()
      window.addEventListener('CookiebotOnLoad', () => window.location.reload())
    }  
  }
}
