import { Controller } from "stimulus"
import { NAVBAR_HEIGHT } from "../../components/utils/constants.js"

const FILTERS_QUERY_PARAMS = ['content_format[]', 'duration', 'skill_level[]', 'instructor[]']

export default class extends Controller {
  connect() {
    if(this._urlHasFiterQuery()) {
      this._scrollToContent()
    }
  }

  _scrollToContent() {
    setTimeout(() => {
      document.querySelector('[name="content-filters__content-section"]').scrollIntoView({ behavior: "smooth", block: "start"})

      window.scrollTo(0, document.querySelector('[name="content-filters__content-section"]').getBoundingClientRect().top - NAVBAR_HEIGHT)
    }, 500)
  }

  _urlHasFiterQuery() {
    const url = new URL(window.location.href)

    return FILTERS_QUERY_PARAMS.find(query_param_name => url.searchParams.has(query_param_name))
  }
}
